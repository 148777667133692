import { Atom } from ":mods";

export function createQuestionInputs() {
  const Answer = Atom.Form.createTextArea(undefined,{
      required:true

  });
  const Actions = Atom.Form.createFormActions(
    {
      Answer,
    }
  );

  return {
    Actions,
    Answer
  };
}
